import Head from "next/head";
import type {NextPage} from "next";
import LoginView from "../components/common/LoginView";
import {useRouter} from "next/router";
import FooterView from "../components/common/Footer";
import {handleLogout} from "components/utils"; import React from "react";

const Login: NextPage = (props) => {
    const router = useRouter()
    const forLogin = router.query["for"] ? router.query["for"].toString() : null

    return (
        <div>
            <Head>
                <title>QMe - Clinic/Hospital Management | Login</title>
                <link rel="canonical" href="https://www.qme.co.in/" />
                <meta property="og:image" content="/og.png"/>
                <meta property="og:title" content="Effortless Patient Management & Token-Based Appointments | QME"/>
                <meta property="og:description" content="Experience efficient stock control, innovative token-based scheduling, and smart queues for unparalleled patient experiences at QME. Read for more!"/>
                <meta name="twitter:image" content="/og.png"/>
                <meta name="robots" content="index, follow"/>
<script
                    key="schema-jsonld"
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "name": "QMe - Hospital and Clinic Management",
                            "image": "https://www.qme.co.in/logo.svg",
                            "description": "Effortless patient management, efficient stock and inventory control, unparalleled patient experience through innovative token-based appointments and smart queues and much more.",
                            "url": "https://www.qme.co.in/",
                            "telephone": "+919971513514",
                            "emailid": "contact@qme.co.in",
                            "sameAs": ["https://www.facebook.com/qmehmis", "https://www.instagram.com/qmehmis", "https://www.youtube.com/@qmehmis"]
                        }, null, "\t") }}
                />
                <meta name="description" content="QMe - Clinic/Hospital Management | Login"/>
                <meta name="keywords" content="electronic medical records software, emr, ehr"/>
            </Head>
            <div className="bg-white">
                <div className="pb-5"><LoginView for={forLogin}/></div>
                <div className="pt-5"><FooterView/></div>
            </div>
        </div>
    );
};


export default Login;
