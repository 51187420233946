import {handleLogout} from "components/utils";
import React, {useEffect, useState} from 'react';
import {useCookies} from "react-cookie";
import axios from "axios";
import * as ga from "../../lib/gtag";
import {useRouter} from "next/router";
import {validatePhoneNumber} from "../utils";
import {
    BILLING,
    CLIENT,
    RADIOLOGY,
    hasPermissions,
    hasPermissionsForManagePage,
    INVENTORY,
    IPD,
    OPD, LAB, OPHTHALMOLOGY
} from "../permissions";
import {cleanCookies} from "universal-cookie/lib/utils";

const LoginView = (props: { for: string }) => {
    const [error, setError] = useState(null)
    const [otpGenerated, setOtpGenerated] = useState(false)
    const [authenticated, setAuthenticated] = useState(false)
    const [hospitalOptions, setHospitalOptions] = useState([])
    const [hospitalSelection, setHospitalSelection] = useState<string>()
    const [userName, setUserName] = useState<string>()
    const [userId, setUserId] = useState<string>()
    const [mobileNumber, setMobileNumber] = useState(null)
    const [enteredOtp, setEnteredOtp] = useState(null)
    const [role, setRole] = useState("client")
    const [cookies, setCookie] = useCookies(['qme', 'token']);
    const [loading, setLoading] = useState(false)
    const router = useRouter()

    function redirectToUserPage(cookie) {
        if (cookie && cookie["permissions"]) {
            if (cookie["role"] == "admin") {
                window.location.href = window.location.origin + "/admin"
            } else if (hasPermissionsForManagePage(cookie)) {
                window.location.href = window.location.origin + "/eadmin"
            } else if (hasPermissions(cookie, CLIENT)) {
                window.location.href = window.location.origin + "/client"
            } else if (hasPermissions(cookie, OPD)) {
                window.location.href = window.location.origin + "/opd"
            } else if (hasPermissions(cookie, IPD)) {
                window.location.href = window.location.origin + "/ipd"
            } else if (hasPermissions(cookie, RADIOLOGY)) {
                window.location.href = window.location.origin + "/radiology"
            } else if (hasPermissions(cookie, INVENTORY) || hasPermissions(cookie, BILLING)) {
                window.location.href = window.location.origin + "/inventory"
            } else if (hasPermissions(cookie, LAB)) {
                window.location.href = window.location.origin + "/lab"
            } else if (hasPermissions(cookie, OPHTHALMOLOGY)) {
                window.location.href = window.location.origin + "/ophthalmology"
            } else {
                window.location.href = window.location.origin
            }
        }
    }

    function loginToEntity(qrCode: string, entityQr: string, userName: string, userId: string, entity: any, permissions: number) {
        if (entity) {
            localStorage.setItem("opd", entity.qmeEnabled)
            localStorage.setItem("ipd", entity.ipdEnabled)
            localStorage.setItem("inventory", entity.inventoryEnabled)
            localStorage.setItem("radiology", entity.radiologyEnabled)
            localStorage.setItem("lab", entity.labEnabled)
        } else {
            localStorage.setItem("opd", "true")
            localStorage.setItem("ipd", "true")
        }
        localStorage.setItem("role", role)
        if (role == "client") {
            const cookie = {
                "role": "client",
                "phone": mobileNumber,
                "active": false,
                "qr": qrCode,
                "entity_qr": entityQr,
                "name": userName,
                "id": userId,
                "permissions": permissions
            }
            setCookie('qme', cookie, {maxAge: 2630000})
            redirectToUserPage(cookie)
        } else {
            const cookie = {
                "role": role,
                "phone": mobileNumber,
                "entity_qr": entityQr,
                "name": userName,
                "id": userId,
                "permissions": permissions
            }
            setCookie('qme', cookie, {maxAge: 2630000})
            redirectToUserPage(cookie)
        }
    }

    const handleSelectEntity = (e) => {
        e.preventDefault()
        const hospital = hospitalOptions.filter(ho => ho.entityQr == hospitalSelection)[0]
        loginToEntity(hospital.qr, hospitalSelection, userName, userId, hospital.entity, hospital.permissions)
    }

    const onFinish = (e) => {
        e.preventDefault()
        if (!otpGenerated) {
            if (!validatePhoneNumber(mobileNumber)) {
                setError("* Please provide correct mobile number")
                return
            } else {
                setError(null)
            }
            setLoading(true)
            axios.post('/api/backend/user/send/otp', {mobile: mobileNumber, role: role})
                .then(resp => {
                    if (resp.data) {
                        setOtpGenerated(true)
                    } else {
                        setError("* User not found. Please use registered mobile number")
                    }
                })
                .catch(err => {
                    console.error(err);
                    if (err.response && err.response!.status && err.response!.status === 403) {
                        handleLogout()
                    }
                    ;
                }).finally(() => setLoading(false));
        } else {
            setLoading(true)
            axios.post('/api/backend/user/validate/otp', {mobile: mobileNumber, otp: enteredOtp, role: role})
                .then(resp => {
                    if (!resp.data.valid) {
                        setError("* Incorrect OTP")
                        return
                    } else {
                        setError(null)
                    }
                    ga.event({
                        action: "login-triggered",
                        params: {
                            mobile: mobileNumber,
                            role: role
                        }
                    });
                    console.log("taking to role page")
                    setCookie('token', resp.data.token, {maxAge: 2630000})
                    if (role == "admin") {
                        localStorage.setItem("opd", "true")
                        localStorage.setItem("ipd", "true")
                        localStorage.setItem("inventory", "true")
                        localStorage.setItem("radiology", "true")
                        localStorage.setItem("lab", "true")
                        setCookie('qme', {
                            "role": "admin",
                            "phone": mobileNumber,
                            "name": resp.data.qrVsName[0].userName,
                            "id": resp.data.qrVsName[0].userId,
                            "permissions": resp.data.qrVsName[0].permissions
                        }, {maxAge: 2630000})
                        window.location.href = window.location.origin + "/admin"
                    }
                    if (resp.data.qrVsName.length == 1) {
                        loginToEntity(resp.data.qrVsName[0].qr, resp.data.qrVsName[0].entityQr, resp.data.qrVsName[0].userName, resp.data.qrVsName[0].userId, resp.data.qrVsName[0].entity, resp.data.qrVsName[0].permissions);
                    } else {
                        setAuthenticated(true)
                        setHospitalOptions(resp.data.qrVsName)
                        setHospitalSelection(resp.data.qrVsName[0].entityQr)
                        setUserName(resp.data.qrVsName[0].userName)
                        setUserId(resp.data.qrVsName[0].userId)
                    }
                })
                .catch(err => {
                    setError(null);
                    console.error(err);
                    if (err.response && err.response!.status && err.response!.status === 403) {
                        setError(err.response.data);
                        //handleLogout()
                        cleanCookies();
                    }
                }).finally(() => setLoading(false));
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    function getLoginDetails() {
        return <>

            {!authenticated &&
                <div className="py-5">
                    <h1 className="text-primary fs-5 mb-3">Clinic & Hospital User Login</h1>
                    <select className="form-select mb-3"
                            value={role}
                            onChange={e => {
                                setRole(e.target.value)
                                setOtpGenerated(false)
                            }}
                    >
                        <option value="client">Doctor</option>
                        <option value="entity_admin">Hospital Admin</option>
                        <option value="fd_exec">Front Desk</option>
                        <option value="opd_manager">OPD Manager</option>
                        <option value="ipd_executive">IPD Executive</option>
                        <option value="ipd_manager">IPD Manager</option>
                        <option value="nursing_staff">Nursing Staff</option>
                        <option value="inv_manager">Pharmacy Manager</option>
                        <option value="sales_exec">Pharmacy Billing Executive</option>
                        <option value="lab_manager">Lab Manager</option>
                        <option value="radiologist">Radiologist</option>
                        <option value="ophthalmic_technician">Ophthalmic Technician</option>
                        <option value="custom_role">Custom Role</option>
                        <option value="admin">Admin</option>
                    </select>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">+91 -</span>
                        </div>
                        <input type="tel" placeholder="Enter phone number"
                               onChange={(e) => {
                                   setMobileNumber(e.target.value)
                                   setOtpGenerated(false)
                               }}
                               className="form-control"/>
                    </div>
                    {!otpGenerated && <button onClick={onFinish}
                                              className="form-control btn btn-info">
                        {loading &&
                            <div className="text-center">
                                <div className="spinner-border" role="status">
                                </div>
                            </div>
                        }
                        {!loading && <div className="text-white">Generate OTP</div>}
                    </button>
                    }
                    {otpGenerated &&
                        <div className="">
                            <input type="tel" maxLength={6} placeholder="Enter OTP"
                                   onChange={(e) => setEnteredOtp(e.target.value)}
                                   className="form-control my-3"/>
                            <button onClick={onFinish}
                                    className="form-control btn btn-info">
                                {loading &&
                                    <div className="text-center">
                                        <div className="spinner-border" role="status">
                                        </div>
                                    </div>
                                }
                                {!loading && <div className="text-white">Login</div>}
                            </button>
                        </div>
                    }
                    {error &&
                        <div className="my-3 text-danger fs-6">{error}</div>
                    }
                </div>

            }
            {authenticated &&
                <>
                    <div className="mb-2 text-secondary">Please Select the Clinic/Hospital</div>
                    <select className="form-select mb-3"
                            value={hospitalSelection}
                            onChange={e => {
                                setHospitalSelection(e.target.value)
                            }}
                    >
                        {hospitalOptions.map(opt => {
                            return <option value={opt.entityQr} key={opt}>{opt.name}</option>
                        })}
                    </select>
                    <button onClick={handleSelectEntity}
                            className="form-control btn btn-info"> Login
                    </button>
                </>
            }
        </>;
    }

    return (
        <div className="row">
            <div className="col col-md-6 offset-md-3 d-grid pt-5">
                <form className="card shadow p-5 d-none d-md-block">
                    {getLoginDetails()}
                </form>
                <form className="px-3 d-md-none">
                    {getLoginDetails()}
                </form>
            </div>
        </div>
    );
};

export default LoginView;